.App {
  background: url('/public/img/mainBg.png') no-repeat center center;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  position: relative;
  overflow: scroll;
}




.zeus {
  position: absolute;
  height: 753px;
  width: 385px;
  top: -100px;
  @media (max-width: 1300px) {
    top: -85px;
    width: 345px !important;
    height: 603px !important;
  }

  @media (max-width: 600px) {
    top: -50px;
    width: 273px !important;
    height: 528px !important;
  }
}



.logo {
  position: absolute;
  left: 104px;
  top: 30px;
  width: 326px;
  height: 80px;

  @media (max-width: 1300px) {
    width: 160px !important;
    height: 30px !important;
    left: 20px !important;
    top: 20px !important;
  }

  @media (max-width: 600px) {
    left: 10px !important;
    top: 10px !important;
  }
}

.olympus {
  position: absolute;
  right: 104px;
  top: 30px;
  width: 236px;
  height: 183px;

  @media (max-width: 1300px) {
    width: 129px !important;
    height: 100px !important;
    right: 20px !important;
    top: 20px !important;
  }

  @media (max-width: 600px) {
    width: 62px !important;
    height: 48px !important;
    right: 10px !important;
    top: 10px !important;
  }
}



.mobileDisplay {
  position: absolute;
  width: 500px;
  height: 245px;

  @media (max-width: 600px) {
    width: 276px !important;
    height: 136px !important;
  }
}

.center {
  position: absolute;
  top: 420px;
  width: 500px;
  height: 245px;
  
  @media (max-width: 600px) {
    top: 440px;
    width: 276px !important;
    height: 136px !important;
  }
}

.logo1000x {
  width: 130px;
  height: 130px;
  top: 110px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;

   
  @media (max-width: 600px) {
    top: 15px;
    width: 110px !important;
    height: 110px !important;
  }
}

.text_container {
  background: url('/public/img/bgText.png') no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 450px;
  transform:translate(0, -50%) ;
  width: 712px;
  height: 358px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  p {
    font-family: Mulish;
    font-size: 25px;
    font-style: italic;
    font-weight: 1000;
    line-height: 35px;
    margin: 0;
    max-width: 405px;
    text-align: center;
    text-transform: uppercase;
  }

  span {
    color: #FFEA00;
  }

  @media (max-width: 1300px) {
    width: 612px;
    height: 308px;
    top: 370px;

  }

  @media (max-width: 600px) {
    width: 356px !important;
    height: 209px !important;
    top: 340px;
    p {
      font-family: Mulish;
      font-size: 16px;
      line-height: 20px;
      max-width: 275px;
     
    }
  }
}

.btn {
  position: absolute;
  bottom: -35px;
  width: 600px;
  height: 96px;
  border-image-source: linear-gradient(116.97deg, rgba(255, 255, 255, 0) 16.71%, rgba(255, 255, 255, 0.4) 98.19%);
  animation: pulse 2s infinite ease-in-out; 
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    width: 256px !important;
    height: 48px !important;
    bottom: -20px !important;
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
}

.btnImg {
  width: 600px;
  height: 96px;

  @media (max-width: 600px) {
    width: 276px !important;
    height: 58px !important;
    
  }
}

.footer {
  position: absolute;
  bottom: 0px;
}



.left {
  position: absolute;
  left: 104px;
  bottom: 104px;  
  width: 500px;
  height: 245px;
}

.right {
  position: absolute;
  right: 104px;
  bottom: 104px;
  width: 500px;
  height: 245px;
}

.left1000x {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 40px;
  bottom: 18px;
  }

.right1000x {
  width: 200px;
  height: 200px;
  position: absolute;
  right: 40px;
  bottom: 18px;

}

.leftDisplay {
  position: absolute;
  width: 500px;
  height: 245px;
}

.rightDisplay {
  position: absolute;
  width: 500px;
  height: 245px;
}
